import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import News from './pages/News/News';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Profile from './pages/Profile/Profile';
import SinglePost from './pages/SinglePost/SinglePost';
import { ok } from 'assert';
import ContactUs from './pages/ContactUs/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import Reports from './pages/Reports/Reports'
import { User } from './compos/SinglePostHelper/SinglePostHelper';
import { UserProvider, useUser } from './UserContext';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';
import SingleReport from './pages/SingleReport/SingleReport';

function App() {
	// const [user, set_user] = useState<User | null>(null);
	const { user, set_user } = useUser();
	console.log(user);
	// useEffect(() => {
	// 	{
	// 		var requestOptions = {
	// 			method: 'GET',
	// 			redirect: 'follow' as RequestRedirect,
	// 		};
	// 		// var ok = {
	// 		// 	statusCode: 401,
	// 		// 	message: 'Please verify phone number with OTP',
	// 		// 	error: 'Unauthorized',
	// 		// };
	// 		// var hey = ok;

	// 		fetch('https://backend.b-reporter.com/user/myProfile', requestOptions)
	// 			.then((response) => response.json())
	// 			.then(async (result) => {
	// 				var temp = JSON.stringify(result)
	// 				if (temp === '{"statusCode":401,"message":"Please verify phone number with OTP","error":"Unauthorized"}') {
	// 					set_user(null);
	// 				} else {
	// 					set_user(result);
	// 				}
	// 				console.log(result);
	// 			})
	// 			.catch((error) => console.log('error while fetching profile', error));
	// 	}
	// }, []);

	return (
		
		<div className='App'>
		<Routes>
        <Route path='/' element={<Home />} />
        <Route path='/news' element={<News />} />
		<Route path='/reports' element={<Reports />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/getpost/:postId' element={<SinglePost />} />
		<Route path='/getreport/:reportId' element={<SingleReport />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/policy' element={<PrivacyPolicy />} />
        <Route path='/admin' element={<AdminPanel />} />
		<Route path='/deleteAccount' element={<DeleteAccount />} />
      </Routes>
		</div>
		
	);
}

export default App;
