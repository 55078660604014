import React, { useEffect } from 'react';
import Header from '../../compos/Header/Header';
import AllPosts from '../../compos/AllPosts/AllPosts';
import './News.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { LoginProps } from '../Login/Login';
import MyFeed from '../../compos/AllPosts/MyFeed';
import { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import { useUser } from '../../UserContext';

function News() {
	const {user,set_user} = useUser();
	if (user == null) {
		return (
			<div>
				<Navigate to='/login' />
			</div>
		)
	}
	else{
		return (
			<div>
				<div>
					<Header />
				</div>
				<MyFeed />	
			</div>
		);
	}
}

export default News;
