import React, { useState, useEffect } from 'react';
import './UserAdminPanel.css';
import { error } from 'console';
import Post from '../../compos/Post/Post';

const UserAdminPanel = () => {

  interface Media {
    internalURL: string;
    format: string;
    latitude: string;
    longitude: string;
    createdAt: string;
  }
  
  interface Post {
    id: number;
    media: Media[];
    title: string;
    body:string
    user:{name:string,
      id:number,
      profilepicURL:string
    }
    tags: string;
    isAnonymous: boolean;
    status: string;
    likeCount: number;
    commentCount: number;
    isLiked: number;
    createdAt: string;
    updatedAt: string;
  }

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [userData, setUserData] = useState(null);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>('');
  const [anonymousUsers, setAnonymousUsers] = useState([]);
  const [userPost, setUserPost] = useState<Post[]>([]);

  const formatPhoneNumber = (number: string) => {
    return `91${number}`;
  };

  const handleGetUser = async () => {
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const requestOptions = {
			  method: 'GET',
			  redirect: 'follow' as RequestRedirect,
			  credentials: 'include' as RequestCredentials
			};
      const response = await fetch(`https://backend.b-reporter.com/users/${formattedNumber}`,requestOptions);
      const data = await response.json();
     
      setUserPost([]);
      setUserData(data);
      setAnonymousUsers([]);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error fetching user data');
    }
  };


  const handleBlockUser = async () => {
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const requestOptions = {
			  method: 'PUT',
			  credentials: 'include' as RequestCredentials
			};
      const response = await fetch(`https://backend.b-reporter.com/users/${formattedNumber}/block`, requestOptions);
      const data = await response.json();
      setUserData(data);
      setAnonymousUsers([]);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error blocking user');
    }
  };

  const handleUnblockUser = async () => {
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const requestOptions = {
			  method: 'PUT',
			  credentials: 'include' as RequestCredentials
			};
      const response = await fetch(`https://backend.b-reporter.com/users/${formattedNumber}/unblock`, requestOptions);
      const data = await response.json();
      setUserData(data);
      setAnonymousUsers([]);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error unblocking user');
    }
  };

  const handleDeleteUser = async () => {
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const requestOptions = {
			  method: 'DELETE',
			  credentials: 'include' as RequestCredentials
			};
      const response = await fetch(`https://backend.b-reporter.com/users/${formattedNumber}`, requestOptions);
      const data = await response.json();
      setUserData(data);
      setAnonymousUsers([]);
      setResponseMessage(JSON.stringify(data));
    } catch (error) {
      setResponseMessage('Error deleting user');
    }
  };

  const handleGetPosts = async () => {
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const bodyData = { 'phoneNumber': formattedNumber };
      const response = await fetch(`https://backend.b-reporter.com/api/myPostsForAdmin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
        credentials: 'include' as RequestCredentials
      });
      const data = await response.json();
      console.log(data);
      setUserData(null);
     
      setUserPost(data);
    
    } catch (error) {
      setResponseMessage('Error fetching posts');
    }
  };

  const handleDeletePost = async (postId: number) => {
    try {
      const response = await fetch(`https://backend.b-reporter.com/api/deletePostByAdmin/${postId}`, { method: 'DELETE',
        credentials: 'include' as RequestCredentials
       });
      if (response.ok) {
        setUserPost(prevPosts => prevPosts.filter(post => post.id !== postId));
      } else {
        console.error('Failed to delete post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const openModal = (action: string) => {
    setActionType(action);
    setIsModalOpen(true);
  };

  const handleConfirmAction = async () => {
    setIsModalOpen(false);
    if (actionType === 'block') {
      handleBlockUser();
    } else if (actionType === 'unblock') {
      handleUnblockUser();
    } else if (actionType === 'delete') {
      handleDeleteUser();
    }
  };

  return (
    <div className="admin-panel-bg">
      <div className="admin-panel-container">
        <h1>Admin Panel</h1>
        <input
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <div className="button-group">
          <button className="admin-panel-button" onClick={handleGetUser}>Get User</button>
          <button className="admin-panel-button" onClick={() => openModal('block')}>Block User</button>
          <button className="admin-panel-button" onClick={() => openModal('unblock')}>Unblock User</button>
          <button className="admin-panel-button" onClick={() => openModal('delete')}>Delete User</button>
          <button className="admin-panel-button" onClick={handleGetPosts}>Get Posts</button>
        </div>
        {userData && (
          <div className="user-details">
            <h2>User Information:</h2>
            <p><span>Name:</span> {userData["name"]}</p>
            <p><span>Gender:</span> {userData["gender"]}</p>
            <p><span>Phone Number:</span> {userData["phoneNumber"]}</p>
            <p><span>Email:</span> {userData["email"]}</p>
            <p><span>Blocked:</span> {userData["isBlocked"] ? 'Yes' : 'No'}</p>
            <p><span>Authority:</span> {userData["is_authority"] ? 'Yes' : 'No'}</p>
            <p><span>District:</span> {userData["district"]}</p>
            <p><span>State:</span> {userData["state"]}</p>
            <p><span>Country:</span> {userData["country"]}</p>
            <p><span>Pincode:</span> {userData["pincode"]}</p>
          </div>
        )}
        {userPost.length > 0 && (
          <div className='user-posts'>
            {userPost.map((post) => (
              <Post key={post.id} post={post} onDelete={handleDeletePost} />
            ))}
          </div>
        )}
        {isModalOpen && (
          <div className="yes-no-modal" tabIndex={-1}>
            <div className="yes-no-modal-dialog">
              <div className="yes-no-modal-content">
                <div className="yes-no-modal-header">
                  <h5 className="yes-no-modal-title">Confirmation</h5>
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setIsModalOpen(false)}></button>
                </div>
                <div className="yes-no-modal-body">
                  <p>Are you sure you want to {actionType} this user?</p>
                </div>
                <div className="yes-no-modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>No</button>
                  <button type="button" className="btn btn-primary" onClick={handleConfirmAction}>Yes</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAdminPanel;
