import React, { useState } from 'react';
import utkarsh from '../../Media/Team/utkarsh.jpeg';
import { LinkedIn } from '@mui/icons-material';
import DeepShikha from '../../Media/Team/DeepShikha-managingDirector.png';
import lalanAdviser from '../../Media/Team/lalan-advisory.jpg';
import siddharth from '../../Media/Team/siddharth.jpeg';
import adityaRanjan from '../../Media/Team/adityaRanjan.jpeg';
import sarthak from '../../Media/Team/sarthak.jpeg';
import sankalp from '../../Media/Team/sankalp.jpeg';
import SaurabhAdviser from '../../Media/Team/Saurabh-advisory.png';
import ChandanAdviser from '../../Media/Team/Chandan-advisory.png';


export function TeamMembers() {
	const Members = [
		{
			name: 'Utkarsh Gupta',
			designation: 'Developer',
			image: utkarsh,
			linkedIn: 'https://www.linkedin.com/in/utkarsh-gupta-821703292/',
		},
		{
			name: 'Siddharth Gupta',
			designation: 'Developer',
			image: siddharth,
			linkedIn: 'https://www.linkedin.com/in/siddharth-gupta-a07b52170/',
		},
		{
			name: 'Aditya Ranjan Sinha',
			designation: 'Developer',
			image: adityaRanjan,
			linkedIn: 'https://www.linkedin.com/in/aditya-ranjan-sinha-a3a25a332/',
		},
		{
			name: 'Sarthak Hans',
			designation: 'Developer',
			image: sarthak,
			linkedIn: 'https://www.linkedin.com/in/sarthak-hans-4a8a53223/',
		},
		{
			name: 'Sankalp Adhya',
			designation: 'Developer',
			image: sankalp,
			linkedIn: 'https://www.linkedin.com/in/sankalp-adhya-67914621b/',
		},
	];

	return (
		// 		<div id='cardContainer' className='min-h-96 w-full flex items-center justify-center gap-10 p-5 flex-wrap'>
		// 			{Members.map((member, index) => (
		// 				<div
		// 					id='card'
		// 					className='w-72 h-96 relative overflow-hidden shadow-gray-900 shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] cursor-pointer bg-white'
		// 					onMouseEnter={() => setHoveredIndex(index)}
		// 					onMouseLeave={() => setHoveredIndex(-1)}
		// 				>
		// 					{/* Profile Image*/}

		// 					<div className={`transition-all duration-1000 ease-in-out ${hoveredIndex === index ? 'h-[17rem]' : 'h-full'}`}>
		// 						<img src={member.image} alt='Profile' className='w-full h-full object-cover' />
		// 					</div>
		// 					{/* caption */}
		// 					<div
		// 						className={`absolute bottom-0 left-0 right-0 bg-black/50 text-white
		//             transition-all duration-500 ease-in-out
		//             ${
		// 							hoveredIndex === index
		// 								? 'translate-y-0 translate-x-0 opacity-100'
		// 								: 'translate-y-full translate-x-0 opacity-0'
		// 						}`}
		// 					>
		// 						<p className='text-2xl font-bold m-0 pt-2'>{member.name}</p>
		// 						<p className='text-sm mb-2'>{member.designation}</p>

		// 						{/* Social Links */}
		// 						<div className='flex justify-center space-x-3 mb-2'>
		// 							{/* <a href='#' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
		// 								<GitHubIcon
		// 									sx={{
		// 										'color': 'white', // Default color
		// 										'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
		// 										'&:hover': {
		// 											color: 'primary.main', // Hover color
		// 											transform: 'scale(1.2)', // Scale up on hover
		// 										},
		// 									}}
		// 								/>
		// 							</a>
		// 							<a href='#' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
		// 								<XIcon
		// 									sx={{
		// 										'color': 'white', // Default color
		// 										'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
		// 										'&:hover': {
		// 											color: 'primary.main', // Hover color
		// 											transform: 'scale(1.2)', // Scale up on hover
		// 										},
		// 									}}
		// 								/>
		// 							</a> */}
		// 							<a
		// 								href={member.linkedIn}
		// 								target='_blank'
		// 								className='text-white'
		// 								onMouseEnter={(e) => e.stopPropagation()}
		// 							>
		// 								<LinkedIn
		// 									sx={{
		// 										'fontSize': 30,
		// 										'color': 'white', // Default color
		// 										'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
		// 										'&:hover': {
		// 											color: '#70BFF3', // Hover color
		// 											transform: 'scale(1.2)', // Scale up on hover
		// 										},
		// 									}}
		// 								/>
		// 							</a>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			))}
		// 		</div>
		<div id='card-container' className='min-h-96 w-full  flex justify-center items-center p-5 gap-5 flex-wrap'>
			{Members.map((member,key)=>
			<div
			id='card'
			className='h-96 w-80 border-black border-2 flex flex-col gap-3 justify-center items-center p-3 rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] '
		>
			<div id='profile' className='w-52 h-52 rounded-full  overflow-hidden border-black border-2'>
				<img src={member.image} alt='Profile' className='w-full h-full object-cover' />
			</div>
			<div id='description' className='w-full h-20 bg-white flex flex-col justify-center items-center gap-1'>
				<span className='font-bold text-2xl'>{member.name}</span>
				<span className='font-normal'>{member.designation}</span>
				<a href={member.linkedIn} target='_blank' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
					<LinkedIn
						sx={{
							'fontSize': 30,
							'color': 'black', // Default color
							'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
							'&:hover': {
								color: '#70BFF3', // Hover color
								transform: 'scale(1.2)', // Scale up on hover
							},
						}}
					/>
				</a>
			</div>
		</div>
			)}
			
		</div>
	);
}

export function Founders() {
	const founders = [
		{
			name: 'Dr. Lalan Kumar',
			designation: 'Founder and NED',
			image: lalanAdviser,
			linkedIn: '',
		},
		{
			name: 'Deepshikha',
			designation: 'Managing Director',
			image: DeepShikha,
			linkedIn: '',
		},
	];

	return (
		<div id='card-container' className='min-h-96 w-full  flex justify-center items-center p-5 gap-5 flex-wrap'>
			{founders.map((member,key)=>
			<div
			id='card'
			className='h-96 w-80 border-black border-2 flex flex-col gap-3 justify-center items-center p-3 rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] '
		>
			<div id='profile' className='w-52 h-52 rounded-full border-black border-2 overflow-hidden'>
				<img src={member.image} alt='Profile' className='w-full h-full object-cover' />
			</div>
			<div id='description' className='w-full h-20 bg-white flex flex-col justify-center items-center gap-1'>
				<span className='font-bold text-2xl'>{member.name}</span>
				<span className='font-normal'>{member.designation}</span>
				<a href={member.linkedIn} target='_blank' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
					<LinkedIn
						sx={{
							'fontSize': 30,
							'color': 'black', // Default color
							'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
							'&:hover': {
								color: '#70BFF3', // Hover color
								transform: 'scale(1.2)', // Scale up on hover
							},
						}}
					/>
				</a>
			</div>
		</div>
			)}
			
		</div>

);
}

export function AdvisoryTeam() {
	const advisors = [
		{
			name: 'Saurabh Singh',
			image: SaurabhAdviser,
			description: 'Mr. Saurabh Singh is working as director of credit at Trifecta Capital. He had been the co-founder of Intellinovate Solutions, Crispy Tokri, and Clove Dental. He is an alumnus of IIT BHU and IIM Ahmedabad.',
		},
		{
			name: 'Chandan Kumar',
			image: ChandanAdviser,
			description: 'Mr. Chandan Kumar is working with Tata Steel as senior planning manager. He is an alumnus of IIT BHU and IIM Ahmedabad.',
		},
	];

	return (
		<div id='card-container' className='min-h-96 w-full  flex justify-center items-center p-5 gap-5 flex-wrap'>
			{advisors.map((adviser,key)=>
			<div
			id='card'
			className='h-96 w-80 border-black border-2 flex flex-col gap-3 justify-center items-center p-3 rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] '
		>
			<div id='profile' className='w-52 h-52 rounded-full border-black border-2 overflow-hidden'>
				<img src={adviser.image} alt='Profile' className='w-full h-full object-cover' />
			</div>
			<div id='description' className='w-full h-32 bg-white flex flex-col justify-center items-center gap-1'>
				<span className='font-bold text-2xl'>{adviser.name}</span>
				<span className='font-light text-sm text-center'>{adviser.description}</span>
			</div>
		</div>
			)}
			
		</div>
	);
}
