import React, { useEffect, useState } from 'react';
import { Link,Navigate } from 'react-router-dom';
import './Header.css';
import BReporter from '../../Media/Icons/BReporter.png';
import {userInfo} from '../../pages/Login/Login';
import reactSelect from 'react-select';
import { User } from '../SinglePostHelper/SinglePostHelper';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';


function Header() {
	const {user, set_user, isAdmin, isLoggedIn,setIsLoggedIn} = useUser();
	const [Nav, set_Nav] = useState<number>(0);
	const [triggerScroll, setTriggerScroll] = useState(false);
	const navigate = useNavigate();


	const handleLogout = (set_user: React.Dispatch<React.SetStateAction<User | null>>) => {
		var requestOptions = {
			method: 'GET',
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials
		};
	
		fetch('https://backend.b-reporter.com/auth/logout', requestOptions)
			.then((response) => response.text())
			.then((result) => {
				set_user(null);
				setIsLoggedIn(false);
				setTimeout(() => {
					navigate('/login');
				  }, 0);
				console.log('Logged out successfully. ', result);
			})
			.catch((error) => console.log('error', error));
	};

	// const toggleMobileNav = () => {
	// 	const navbar = document.getElementById('navbar');
	// 	if (navbar) {
	// 		navbar.classList.toggle('show');
	// 		document.querySelector('.mobile-nav-show')?.classList.toggle('d-none');
	// 		document.querySelector('.mobile-nav-hide')?.classList.toggle('d-none');
	// 	}
	// };
	const toggleMobileNav = () => {
        const navbar = document.getElementById('navbar');
        const overlay = document.getElementById('overlay');
        if (navbar && overlay) {
            navbar.classList.toggle('show');
            overlay.classList.toggle('show');
            document.querySelector('.mobile-nav-show')?.classList.toggle('d-none');
            document.querySelector('.mobile-nav-hide')?.classList.toggle('d-none');
        }
    };

	const closeMobileNav = () => {
        const navbar = document.getElementById('navbar');
        const overlay = document.getElementById('overlay');
        if (navbar && overlay) {
            navbar.classList.remove('show');
            overlay.classList.remove('show');
            document.querySelector('.mobile-nav-show')?.classList.remove('d-none');
            document.querySelector('.mobile-nav-hide')?.classList.add('d-none');
        }
    };
	
	

	useEffect(() => {
		if (triggerScroll) {
		  document.getElementsByClassName('dummy-filler')[0].scrollIntoView();
		  setTriggerScroll(false); // Reset the trigger
		}
	  }, [triggerScroll]);

    useEffect(() => {
        const handleScroll = () => {
            const arr = window.location.href.split('/');
            const nav = document.getElementsByClassName('navbar')[0];
            if (arr[arr.length - 1] === 'news') {
                nav.classList.toggle('sticky', true);
            } else {
                nav.classList.toggle('sticky', window.scrollY >= 10);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



	useEffect(() => {
		var arr = window.location.href.split('/');
		if (arr[arr.length - 1] == 'news' || arr[arr.length - 2] == 'post') {
			set_Nav(1);
			var nav = document.getElementsByClassName('navbar')[0];
			nav.classList.toggle('sticky', true);
		} else if (arr[arr.length - 1] == 'login') set_Nav(3);
		else if (arr[arr.length - 1] == 'policy') set_Nav(4);
		else if (arr[arr.length - 1] == 'contact') set_Nav(5);
		else if (arr[arr.length - 1] == "admin") set_Nav(6);
	},[]);

	return (
		
		<div>
		
			<header id='header' className='header fixed-top d-flex align-items-center'>
			<i className='mobile-nav-toggle mobile-nav-show' onClick={toggleMobileNav}>
							<FontAwesomeIcon icon={faBars} />
						</i>
						<i className='mobile-nav-toggle mobile-nav-hide d-none' onClick={toggleMobileNav}>
							<FontAwesomeIcon icon={faTimes} />
						</i>
				<div className='container d-flex align-items-center justify-content-between '>
				
					<button className='navbar-brand d-flex' onClick={()=>navigate('/')}>
						<img src={BReporter} className='img-fluid' />
						
						<div className='text-center d-flex justify-content-center align-items-start ps-2 flex-column' style={{paddingTop: '28px'}} id='brand'>
							<h3 style={{ lineHeight:'0.7'}}>
							B-Reporter
							</h3>
							{/* <h6 style={{ fontSize: '13px', lineHeight:'1' }}>From B-Reporter</h6> */}
						</div>
					</button>
					
				
					{/* <button
                        className='navbar-toggler'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarTogglerDemo01'
                        aria-controls='navbarTogglerDemo01'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon'></span>
                    </button> */}

					<nav id='navbar' className='navbar '>
						<ul>
							<li className='nav-item'>
								<button className={`nav-link ${Nav == 0 ? 'active' : ''}`} id='nav-link' onClick={()=>navigate('/')}>
									Home
								</button>
							</li>
							<li className='nav-item'>
								<button
									className={`nav-link ${Nav == 2 ? 'active' : ''}`}
									id='nav-link'
									onClick={() => {
										set_Nav(2);
										var arr = window.location.href.split('/');
										if (arr[arr.length - 1].length != 0) {
											
											navigate('/');
											setTimeout(() => {
												document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
											  }, 0);
										} else {
											document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
										}
									}}
								>
								Features
								</button>
							</li>

							<li className='nav-item'>
								<button className={`nav-link ${Nav == 1 ? 'active' : ''}`} id='nav-link' onClick={()=>navigate('/news')}>
									My News
								</button>
							</li>
							{user?.is_authority && 
							<li className='nav-item'>
								<button className={`nav-link ${Nav == 1 ? 'active' : ''}`} id='nav-link' onClick={()=>navigate('/reports')}>
									My Reports
								</button>
							</li>
							}
							<li className='nav-item'>
								<button
									className={`nav-link ${Nav == 2 ? 'active' : ''}`}
									id='nav-link'
									onClick={() => {
										set_Nav(2);
										var arr = window.location.href.split('/');
										if (arr[arr.length - 1].length != 0) {
											
											navigate('/');
											setTimeout(() => {
												document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
											  }, 0);
										} else {
											document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
										}
									}}
								>
								Team
								</button>
							</li>
							
							
							<li className='nav-item'>
								<button className={`nav-link ${Nav == 5 ? 'active' : ''}`} id='nav-link' onClick={()=>navigate('/contact')}>
									Contact
								</button>
							</li>
							{isAdmin && isLoggedIn && (
								<li className="nav-item">
									<button className={`nav-link ${Nav == 6 ? "active" : ""}`} id="nav-link" onClick={()=>navigate('/admin')}>Admin Panel</button>
								</li>
							)}
							
						
						</ul>
						
					
					</nav>
					
					
					 {user ? (
                        <button
                            className={`nav-link ${Nav === 3 ? 'active' : ''}`}
                            onClick={() => handleLogout(set_user)}
                        >
                            Logout
                        </button>
                    ) : (
                        <button className={`nav-link ${Nav === 3 ? 'active' : ''} btn-book-a-table`} onClick={()=>navigate('/login')}>
                            Login
                        </button>
                    )}


					
					
				</div>
			</header>
			<div id="overlay" className="overlay" onClick={closeMobileNav}></div>
				
		</div>
	);
}

export default Header;
