import moment from "moment";

export const intlFormat = (num: number): string => new Intl.NumberFormat().format(Math.round(num * 10) / 10);

// gives number in 1K,1M ... format
export const makeNumber = (num: number): string => {
    if (num >= 1000000)
        return intlFormat(num / 1000000) + 'M';
    if (num >= 1000)
        return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
}

// gives formatted time for comment
export const timeDiff = (time: string): string => {
    var curr = Date.parse(new Date().toISOString())
    var prev = Date.parse(time);
    var ms_Min = 60 * 1000; // milliseconds in Minute
    var ms_Hour = ms_Min * 60; // milliseconds in Hour
    var ms_Day = ms_Hour * 24; // milliseconds in day
    var ms_Mon = ms_Day * 30; // milliseconds in Month
    var ms_Yr = ms_Day * 365; // milliseconds in Year
    var diff = curr - prev; //difference between dates.
    // If the diff is less then milliseconds in a minute
    if (diff < ms_Min) {
        return Math.round(diff / 1000) + ' sec';

        // If the diff is less then milliseconds in a Hour
    } else if (diff < ms_Hour) {
        return Math.round(diff / ms_Min) + ' min';

        // If the diff is less then milliseconds in a day
    } else if (diff < ms_Day) {
        return Math.round(diff / ms_Hour) + ' hrs';

    }
    return moment.utc(time).format("DD/MM/yyyy");
}