import React, { useState, useEffect } from 'react';
import Header from '../../compos/Header/Header';
import BasicSlider from '../../compos/BasicSlider/BasicSlider';
import Footer from '../../compos/Footer/Footer';
import './Home.css';
import { LoginProps } from '../Login/Login';
import utkarsh from '../../Media/Team/utkarsh.jpeg';
import lalan from '../../Media/Team/lalan_kumar.png';
import lalanAdviser from '../../Media/Team/lalan-advisory.jpg';
import DeepShikha from '../../Media/Team/DeepShikha-managingDirector.png';
import CCTVImg from '../../Media/Images/Slide_CCTV.jpeg';
import PostFactoImg from '../../Media/Images/post-facto-feature-img.jpeg';
import Sensitive from '../../Media/Images/sensitive.jpg';
import People from '../../Media/Images/people.jpg';
import LadyWithWatch from '../../Media/Images/lady-with-watch-croam-img.jpg';
import GroundReport from '../../Media/Images/ground-report-img.jpg';
import RecordReport from '../../Media/Images/record-and-report-img.jpg';
import LocationAware from '../../Media/Images/locationAware.png';
import Riskometer from '../../Media/Images/riskometer-img.jpg';
import shresth from '../../Media/Team/shresth_ojha.png';
import gopal from '../../Media/Team/gopal.png';
import mayank from '../../Media/Team/mayank.jpeg';
import suraaj from '../../Media/Team/suraaj.jpeg';
import placeholder from '../../Media/Team/placeholder.png';
import simranY from '../../Media/Team/simran_yadav.png';
import ashrut from '../../Media/Team/ashrut_sinha.png';
import AboutUsImage from '../../Media/Images/Breporterpng.jpg';
import { useUser } from '../../UserContext';
import FeatureSlider from '../../compos/FeatureSlider/FeatureSlider';
import { AdvisoryTeam, Founders, TeamMembers } from '../../compos/Teams/TeamMembers';

const Home = () => {
	const { user, setIsAdmin, setIsLoggedIn, set_user } = useUser();
	const [animationClass, setAnimationClass] = useState('');

	const checkIfAdmin = async (setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
			const response = await fetch('https://backend.b-reporter.com/api/isAdmin', {
				method: 'GET',
				credentials: 'include',
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();

			// Check if the API response indicates the user is an admin
			setIsAdmin(data);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
			setIsAdmin(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem('authToken');
		if (token) {
			const profileRequestOptions: RequestInit = {
				method: 'GET',
				redirect: 'follow',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};

			fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions)
				.then((response) => {
					if (response.ok) {
						return response.json();
					} else {
						throw new Error('Profile request failed');
					}
				})
				.then((result) => {
					set_user(result);
					setIsLoggedIn(true);
					checkIfAdmin(setIsAdmin);
				})
				.catch((error) => {
					console.log('Error occurred while fetching profile:', error);
					setIsLoggedIn(false);
					set_user(null);
				});
		} else {
			setIsLoggedIn(false);
			set_user(null);
		}
	}, [set_user, setIsLoggedIn, setIsAdmin]);
	return (
		<div className='section-bg'>
			<Header />
			<BasicSlider />

			{/* -------------*******-------------- */}

			{/*feature section*/}
			<div className='feature-scroll-point section-bg flex items-center justify-center flex-col gap-10'>
				<FeatureSlider />
			</div>

			{/* Basic Heading changed */}
			{/* <div className='team-scroll-point'></div>
			<div className='section-header pt-5'> */}

			<div className='team p-5 flex justify-center items-center flex-col gap-3'>
				<div className='founder-heading'>
					<h1 className='font-bold'>Founders</h1>
					<Founders />
				</div>

				<div className='members-heading'>
					<h1 className='font-bold'>Team Memebers</h1>
				</div>
					<TeamMembers />
			</div>

			<div className='team-container '>
				<div className='advisory-panel flex flex-col justify-center '>
					{/* <h4>People</h4>
    <div className='managing-director'>
      <h5>Managing Director</h5>
      <div className='advisory-row'>
        <h6 className='text-start'>Deep Shikha</h6>
        <div className='img-container float-end ms-3 mb-3'>
          <img src={DeepShikha} alt='...' className='rounded-circle zoom-img normal-image' />
        </div>
        <p><span className='bold-name'></span> </p>
      </div>
    </div>
	<br /><br /> */}
					<h1 className='font-bold'>Advisory Team</h1>
					<AdvisoryTeam />
				</div>
			</div>

		{/* About us section end */}

			<br />
			<br />
			<br />
			<br />
			<Footer />
		</div>
	);
};

export default Home;

