// import './MyFeed.css';
import RiskoMeter from '../RiskoMeter/RiskoMeter';
import React, { useState, useEffect,useRef } from 'react';
import Geocode from 'react-geocode';
import GN_Icon from '../../Media/Icons/TopicsIcons/GN_Icon.svg';
import Events_Icon from '../../Media/Icons/TopicsIcons/Events_Icon.svg';
import Research_Icon from '../../Media/Icons/TopicsIcons/Research_Icon.svg';
import Crime_Icon from '../../Media/Icons/TopicsIcons/Crime_Icon.svg';
import Fraud_Icon from '../../Media/Icons/TopicsIcons/Fraud_Icon.svg';
import Politics_Icon from '../../Media/Icons/TopicsIcons/Politics_Icon.svg';
import Initiatives_Icon from '../../Media/Icons/TopicsIcons/Initiatives_Icon.svg';
import Opinions_Icon from '../../Media/Icons/TopicsIcons/Opinions_Icon.svg';
import GN_Icon_Selected from '../../Media/Icons/TopicsIcons/GN_Icon_Selected.svg';
import Events_Icon_Selected from '../../Media/Icons/TopicsIcons/Events_Icon_Selected.svg';
import Research_Icon_Selected from '../../Media/Icons/TopicsIcons/Research_Icon_Selected.svg';
import Crime_Icon_Selected from '../../Media/Icons/TopicsIcons/Crime_Icon_Selected.svg';
import Fraud_Icon_Selected from '../../Media/Icons/TopicsIcons/Fraud_Icon_Selected.svg';
import Politics_Icon_Selected from '../../Media/Icons/TopicsIcons/Politics_Icon_Selected.svg';
import Initiatives_Icon_Selected from '../../Media/Icons/TopicsIcons/Initiatives_Icon_Selected.svg';
import Opinions_Icon_Selected from '../../Media/Icons/TopicsIcons/Opinions_Icon_Selected.svg';
import Placeholder from '../../Media/Team/placeholder.png'
// import { User } from '../SinglePostHelper/SinglePostHelper';
import { useNavigate } from 'react-router-dom';
// import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { makeNumber, timeDiff } from '../../utils/calc';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useUser } from '../../UserContext';
import ImageModal from '../ImageModal/ImageModal';

Geocode.setApiKey('AIzaSyBMyRKq-C8PhUUCZDXUg6y1MV4_k523T_8');
Geocode.setLanguage('en');
// Geocode.setRegion('in');
Geocode.setLocationType('ROOFTOP');

type TopicsType = {
    name: string;
    img: string;
    filter: boolean;
    key: number;
};

// Define MediaType and PostType
type MediaType = {
    format: string;
    internalURL: string;
    latitude: string;
    longitude: string;
  };
  
  type PostType = {
    id: number;
    title: string;
    media: MediaType[];
    isAnonymous: boolean;
    updatedAt: string;
    createdAt:string
    commentCount: number;
    likeCount: number;
    tags: string;
    isLiked:number;
    user: { name: string;
        id:number;
        profilepicURL:string;
     };
  };

export type AllPostDataType = {
    id: number;
    title: string;
    content?: string;
    format: string;
    image_url: any;
    media_filename: string;
    name: string;
    location: string;
    time: string;
    commentCount: number;
    likeCount: number;
    tags: string;
    profilepicURL:string;
    isLiked:number;

};


interface RiskData {
    No_of_Faces: number;
    No_of_Fire_Objects: number;
    No_of_Persons: number;
    No_of_Weapons: number;
    Risk_Factor: number;
  }

const handleTopicToggle = (
    data: TopicsType,
    Topics: TopicsType[],
    set_Topics: React.Dispatch<React.SetStateAction<TopicsType[]>>
) => {
    const topics_selected_img_array = [
        GN_Icon_Selected,
        Events_Icon_Selected,
        Research_Icon_Selected,
        Crime_Icon_Selected,
        Fraud_Icon_Selected,
        Politics_Icon_Selected,
        Initiatives_Icon_Selected,
        Opinions_Icon_Selected,
    ];
    const topics_notselected_img_array = [
        GN_Icon,
        Events_Icon,
        Research_Icon,
        Crime_Icon,
        Fraud_Icon,
        Politics_Icon,
        Initiatives_Icon,
        Opinions_Icon,
    ];
    set_Topics(
        Topics.map((topic) => {
            if (topic === data) {
                if (topic.filter === false) {
                    return { ...topic, img: topics_selected_img_array[topic.key - 1], filter: !topic.filter };
                } else {
                    return { ...topic, img: topics_notselected_img_array[topic.key - 1], filter: !topic.filter };
                }
            } else {
                return topic;
            }
        })
    );
};

function MyFeed() {
    const {user} = useUser();
    const navigate = useNavigate();
    const [AllPostData, set_AllPostData] = useState<AllPostDataType[]>(
         [
        // {
        //     id: 26,
        //     title: "Test Data Population",
        //     content: "Hello Hello Hello",
        //     format: 'image/jpg',
        //     image_url:"C:\Users\Sankalp Adhya\Desktop\cloned\BReporter-Website\src\Media\Images\people.jpg",
        //     media_filename:'people.jpg',
        //     name: "Sankalp",
        //     location:"",
        //     time: '2022-12-08T02:13:37.000Z',
        //     commentCount: 10,
        //     likeCount: 100,
        //     tags: "Delhi",
        //     profilepicURL: '',
        //     isLiked: 1
        // }
        ]
    );
    const [Topic, set_Topic] = useState<number>(0);
    const [Time, set_Time] = useState<number>(0);
    const [Distance, set_Distance] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [likes, setLikes] = useState<Record<number, boolean>>({}); // State for likes
    const [riskData, setRiskData] = useState<RiskData | null>(null);
    const [showRiskMeter, setShowRiskMeter] = useState(false);
    
    const storePreviousLocation = () => {
		localStorage.setItem('previousLocation', '/news');
	  };
	
	  // Navigate back to the stored location (e.g., 'My Feed') when the component mounts
	  useEffect(() => {
		const previousLocation = localStorage.getItem('previousLocation');
		if (previousLocation) {
		  localStorage.removeItem('previousLocation'); // Clear stored location
		  navigate(previousLocation); // Navigate back to 'My Feed' or stored location
		}
	  }, [navigate]);

      const navigateToPost = (postId: string) => {
        // Store the previous location before navigating to individual post
        storePreviousLocation();
        navigate(`/getpost/${postId}`);
      };
   
    // Reference to the container
    const postContainerRef = useRef<HTMLDivElement>(null);

    const formatDateToRelativeTime = (dateString:string) => {
        const date = parseISO(dateString);
        return formatDistanceToNow(date, { addSuffix: true });
      };

      const handleRiskometerClick = async (mediaFilename: string) => {
        try {
          const response = await fetch(`http://20.244.37.235:8085/analyze/${mediaFilename}`, {
            method: 'GET',
            redirect: 'follow' as RequestRedirect,
            credentials: 'include' as RequestCredentials,
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRiskData(data);
          setShowRiskMeter(true);
        } catch (error) {
          console.error('Error fetching risk data:', error);
        }
      };
    
      const handleRiskometerClose = () => {
        setShowRiskMeter(false);
      };

    const reactPost = async (postId: number, type: string, isAnonymous: boolean) => {
        
        const requestOptions: RequestInit = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ postId, type, isAnonymous }),
          credentials: 'include',
        };
      
        try {
          const response = await fetch('https://backend.b-reporter.com/api/react', requestOptions);
          
       
          
          if (response.status !== 200 && response.status!==201) {
            // Revert the state changes if the request fails
            setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
            set_AllPostData((prevPosts) =>
              prevPosts.map((post) =>
                post.id === postId
                  ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) }
                  : post
              )
            );
          }
        } catch (error) {
         
          // Revert the state changes if there's an error
          setLikes((prevLikes) => ({ ...prevLikes, [postId]: type === 'like' ? false : true }));
          set_AllPostData((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? { ...post, likeCount: post.likeCount + (type === 'like' ? -1 : 1) }
                : post
            )
          );
        }
      };
        
      const handleLikeClick = (postId: number, isAnonymous: boolean) => {
        const isLiked = likes[postId];
        const newType = isLiked ? 'dislike' : 'like';
      
        // Optimistically update the UI
        setLikes((prevLikes) => ({ ...prevLikes, [postId]: !isLiked }));
        set_AllPostData((prevPosts) =>
          prevPosts.map((post) =>
            post.id === postId
              ? { ...post, likeCount: post.likeCount + (isLiked ? -1 : 1) }
              : post
          )
        );
      
        // Perform the API request
        reactPost(postId, newType, isAnonymous);
        console.log(AllPostData);
      };
    
    const fetchPosts = async (page:number) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          credentials: 'include'
        };
       
        const baseUrl = 'https://backend.b-reporter.com/api/';
        const endpoint = Topic === 0 ? 'myFeed' : 'myFeedPro';
        const url = `${baseUrl}${endpoint}?limit=10&offset=${(page - 1) * 10}${Topic !== 0 ? `&topic=${Topic}` : ''}`;
    
        try {
          const response = await fetch(url, requestOptions);
          console.log("this is response from myfeed : ", response);
          const result: PostType[] = await response.json();
    
    
          const usefultemp: AllPostDataType[] = (await Promise.all(result.map(async (each) => {
            console.log("this is each from my feed: ", each);
            if (each.media[0].format.substring(0, 5) !== 'image') return null;
    
            let image = '';
            try {
              const mediaResponse = await fetch('https://backend.b-reporter.com/api/media/' + each.media[0].internalURL, requestOptions);
              const blob = await mediaResponse.blob();
              image = URL.createObjectURL(blob);
              
            } catch (error) {
              console.log('Error fetching media:', error);
              return null;
            }
    
            
            return {
              id: each.id,
              title: each.title,
              content: "",
              format: each.media[0].format.substring(0, 5),
              image_url: image,
              media_filename: each.media[0].internalURL,
              name: each.isAnonymous ? 'Anonymous' : each.user.name,
              location: `(${each.media[0].latitude}, ${each.media[0].longitude})`,
              time: `${each.createdAt}`,
              commentCount: each.commentCount,
              likeCount: each.likeCount,
              isLiked: each.isLiked,
              tags: each.tags,
              profilepicURL: each.user.profilepicURL

            } as AllPostDataType;
          }))).filter((post): post is AllPostDataType => post !== null);

           // Initialize likes state based on fetched posts
        const initialLikes = usefultemp.reduce((acc, post) => {
            acc[post.id] = post.isLiked===1? true:false;// Assuming posts are initially not liked
            return acc;
        }, {} as Record<number, boolean>);
        
    
          set_AllPostData((prevPosts) => [...prevPosts, ...usefultemp]);
          setLikes((prevLikes) => ({ ...prevLikes, ...initialLikes })); // Set the likes state
      
          // Scroll to top after loading more posts
          if (postContainerRef.current) {
            postContainerRef.current.scrollTop = 0;
        }
            } catch (error) {
            console.log('Error fetching posts:', error);
            }
        };

                
            
        useEffect(() => {
            set_AllPostData([]); // Clear existing posts
            setCurrentPage(1); // Reset current page to 1
            fetchPosts(1); // Fetch initial posts for the selected topic
        }, [Topic]);
    
        useEffect(() => {
            if (currentPage > 1) {
                fetchPosts(currentPage);
            }
        }, [currentPage]);
    
      const loadMorePosts = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
<div className='container mx-auto'>
  <div className='flex h-screen'>
    {/* Left Sidebar */}
    <div className='w-1/4 lg:w-1/5 bg-white fixed top-0 left-0 h-full shadow-lg p-4'>
      <nav className="space-y-6">
        {['General News', 'Research', 'Crime', 'Fraud', 'Politics', 'Initiatives', 'Opinions'].map((category, index) => (
          <div key={index}>
            <a
              className={`flex items-center space-x-2 cursor-pointer p-2 rounded-lg hover:bg-gray-100 ${Topic === index ? 'bg-blue-100 text-blue-700' : 'text-gray-700'} no-underline`}
              onClick={() => {
                set_Topic(index);
                setCurrentPage(1);
                set_AllPostData([]);
              }}
            >
              <i className={`fa fa-${category.toLowerCase().replace(' ', '-')}-icon fa-lg`} />
              <span>{category}</span>
            </a>
          </div>
        ))}
      </nav>
    </div>

    {/* Main Feed */}
    <div className='w-full lg:w-3/5 mx-auto mt-4 lg:ml-64'>
      <div className="px-6 py-3 bg-gray-100 rounded-lg shadow-sm mb-6">
        <span className="text-xl font-semibold">Hello {user?.name},</span>
      </div>
      <div className="space-y-6">
        {AllPostData.map(data => (
          <div className="bg-white rounded-lg shadow-md p-4" key={data.id}>
            {showRiskMeter && riskData && (
              <div className="bg-black bg-opacity-50 text-white p-4 absolute top-0 right-0 w-full h-full flex justify-center items-center">
                <button className="absolute top-4 right-4 text-white text-xl" onClick={handleRiskometerClose}>X</button>
                <RiskoMeter
                  value={riskData.Risk_Factor}
                  personCount={riskData.No_of_Persons}
                  facesCount={riskData.No_of_Faces}
                  fireCount={riskData.No_of_Fire_Objects}
                  weaponsCount={riskData.No_of_Weapons}
                />
              </div>
            )}

            {/* Post content */}
            <div className="flex space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="w-12 h-12 rounded-full"
                  src={data.profilepicURL === null || data.name === "Anonymous" ? Placeholder : `https://backend.b-reporter.com/profilepic/${data.profilepicURL}`}
                  alt="profile-pic"
                />
              </div>
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-lg font-semibold">{data.name}</div>
                  <div className="text-sm text-gray-500">{formatDateToRelativeTime(data.time)}</div>
                </div>
                <p className="text-gray-700 mb-2">{data.title}</p>
                <div className="relative mb-4" onClick={() => navigateToPost(data.id.toString())}>
                  <img src={data.image_url} alt="post-image" className="w-full h-64 object-cover rounded-lg shadow-md" />
                  <div className="absolute bottom-0 left-0 bg-gray-800 bg-opacity-50 text-white text-xs p-2">
                    <p>B-Reporter</p>
                    <p>Created {formatDateToRelativeTime(data.time)}</p>
                  </div>
                </div>
                <div className="flex space-x-6 text-sm text-gray-600">
                  <div onClick={() => navigateToPost(data.id.toString())} className="flex items-center space-x-1 cursor-pointer">
                    <i className="bi bi-chat-left" />
                    <span>{data.commentCount}</span>
                  </div>
                  <div onClick={() => handleLikeClick(data.id, data.name === "Anonymous")} className="flex items-center space-x-1 cursor-pointer">
                    <i className={`fa fa-thumbs-up ${likes[data.id] ? 'text-blue-500' : 'text-gray-600'} fa-lg`} />
                    <span>{data.likeCount}</span>
                  </div>
                  <div className="relative">
                    <i className="bi bi-share" data-bs-toggle="dropdown" aria-expanded="false" />
                    <ul className="dropdown-menu absolute right-0 mt-2 w-40 rounded-lg shadow-lg bg-white text-gray-700">
                      {['Email', 'Facebook', 'LinkedIn', 'Reddit', 'Twitter', 'Whatsapp'].map((platform, index) => (
                        <li key={index} className="p-2 cursor-pointer hover:bg-gray-100">
                          <a href={`#`} className="flex items-center space-x-2">
                            <span className={`share-icon`} /> <span>{platform}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="mt-4">
                  <span onClick={() => navigateToPost(data.id.toString())} className="text-blue-500 cursor-pointer">Show More...</span>
                  <p className="text-gray-600 mt-2">{data.content}</p>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Load More Posts Button */}
        <div className="text-center mt-6">
          <button onClick={loadMorePosts} className="text-blue-500 flex items-center justify-center space-x-2 hover:underline">
            <i className="fa fa-chevron-down" />
            <span>Load More</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

    );
}

export default MyFeed;


