import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Footer from '../../compos/Footer/Footer'
import Header from '../../compos/Header/Header'
import './Login.css'
import { User } from '../../compos/SinglePostHelper/SinglePostHelper'
import { useUser } from '../../UserContext';


export type LoginProps = {
	user: User | null;
	set_user: React.Dispatch<React.SetStateAction<User | null>>;
};

type handleLoginSubmitProps = {
	user: User | null;
	set_user: React.Dispatch<React.SetStateAction<User | null>>;
	phoneNumber: string;
	password: string;
};
// Create a userInfo object
export const userInfo = {
	phoneNumber: '',
	password: ''
};



  
  const Login = () => {
	const navigate = useNavigate();
	const [phoneNumber, set_phoneNumber] = useState<string>('');
	const [password, set_password] = useState<string>('');
	const { user, set_user, setIsAdmin, setIsLoggedIn, getPostId, setGetPostId} = useUser();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [showPopup, setShowPopup] = useState(false);

	const togglePasswordVisibility = () => {
	  setIsPasswordVisible(!isPasswordVisible);
	};
	

	const handleLoginSubmit = async ({ user, set_user, phoneNumber, password }: handleLoginSubmitProps) => {
		
		setShowPopup(false); // Hide popup on new submission
		var urlencoded = new URLSearchParams();
		urlencoded.append('phoneNumber', '91' + phoneNumber);
		urlencoded.append('password', password);
	  
		const requestOptions = {
		  method: 'POST',
		  body: urlencoded,
		  redirect: 'follow' as RequestRedirect,
		  credentials: 'include' as RequestCredentials
		};
	  
		try {
		  const loginResponse = await fetch('https://backend.b-reporter.com/auth/loginWeb', requestOptions);
		    
		  if (loginResponse.ok) {
	
			const profileRequestOptions = {
			  method: 'GET',
			  redirect: 'follow' as RequestRedirect,
			  credentials: 'include' as RequestCredentials
			};
	  
			const profileResponse = await fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions);
	  
			if (profileResponse.ok) {
			  const result = await profileResponse.json();
			  
			  if (result.id) {
				set_user(result);
				setIsLoggedIn(true);
				localStorage.setItem('authToken', result.token);
				await checkIfAdmin(setIsAdmin); 
			  }
			  
			} else {
				console.log(profileResponse);
			  console.log('Error occurred while fetching profile:', profileResponse.status);
			}
		  } else {
			console.log('Error occurred while logging in:', loginResponse.status);
			setShowPopup(true); // Show popup on login error
		  }
		} catch (error) {
		  console.log('Error occurred:', error);
		  setShowPopup(true); // Show popup on login error
		}
	  };

	  useEffect(() => {
		const token = localStorage.getItem('authToken');
		if (token) {
		  const profileRequestOptions = {
			method: 'GET',
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
			headers: {
			  'Authorization': `Bearer ${token}`
			}
		  };
	
		  fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions)
			.then(response => response.json())
			.then(result => {
			  if (result.id) {
				set_user(result);
				setIsLoggedIn(true);
				checkIfAdmin(setIsAdmin);
			  }
			})
			.catch(error => console.log('Error occurred while fetching profile:', error));
		}
	  }, [set_user, setIsLoggedIn, setIsAdmin]);
	
	
	  const checkIfAdmin = async (setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
		  const response = await fetch('https://backend.b-reporter.com/api/isAdmin', {
			method: 'GET',
			credentials: 'include',
		  });
	
		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }
	
		  const data = await response.json();
		  
	
		  // Check if the API response indicates the user is an admin
		  setIsAdmin(data);
		} catch (error) {
		  console.error('There was a problem with the fetch operation:', error);
		  setIsAdmin(false);
		}
	  };

	if(getPostId!=="" && user!=null){
	
		 return (
			<div>
				<Navigate to={`/getpost/${getPostId}`} />
			</div>
		);
		 
	}

	else if (user != null  ) {
		
		
		return (
			<div>
				<Navigate to='/news' />
			</div>
		);
	} else {
		return (
			<div className='login-bg d-flex flex-column justify-content-between'>
				<Header  />
				{/* <div className='container login-card-container'>
					<div className='card login-card mx-auto shadow '>
						<div className='card-body'>
							<h1 className='card-title mt-1'>Login</h1>
							<Loginform user={user} set_user={set_user} />
						</div>
					</div>
				</div> */}
				<section className="user">
					<div className="user_options-container">
						<div className="user_options-text">
							<div className="user_options-unregistered">
								<h2 className="user_unregistered-title">New to My News My Reporter </h2>
								<p className="user_unregistered-text">Download our App and Join Now</p>
								<a className="user_unregistered-signup no-underline" id="signup-button" href="https://play.google.com/store/apps/details?id=com.iitd.breporter3" target="_blank">Download</a>
							</div>
						</div>

						<div className="user_options-forms" id="user_options-forms">
							<div className="user_forms-login">
								<h2 className="forms_title">Login</h2>
								<form className="forms_form">
									<fieldset className="forms_fieldset">
										<div className="forms_field">
											<i className='fa fa-solid fa-user mx-2'></i>
											<input placeholder="Phone" className="forms_field-input" required autoFocus
												onChange={(e) => {
													set_phoneNumber(e.target.value);
												}}
												type='text'
												pattern='\d*'
												maxLength={10}
												minLength={10} />
										</div>
										<div className="forms_field">
										
											<i className='fa fa-solid fa-key mx-2'></i>
											<div className="input-container">
											<input type={isPasswordVisible ? "text" : "password"}  placeholder="Password" className="forms_field-input" required
												onChange={(e) => {
													set_password(e.target.value);
													
												}}
											/>
											<button type="button" onClick={togglePasswordVisibility}>
											{isPasswordVisible ? (<i className="bi bi-eye"></i>) : (<i className="bi bi-eye-slash"></i>)}
										  </button>
										  </div>
										</div>
									</fieldset>
									<div className="forms_buttons">
										<input type="submit" value="Log In" className="forms_buttons-action"
											onClick={(e) => {
												e.preventDefault();
												handleLoginSubmit({ user, set_user, phoneNumber, password });
											}}
										/>
									</div>
								</form>
							</div>
							{showPopup && (
								<div className='popup-message'>
									<p>Wrong Credentials</p>
								</div>
							)}
						</div>
					</div>
				</section>
				<Footer />
			</div>
		);
	}
}

export default Login